import Layout from '../components/layout'
import {graphql} from 'gatsby'
import React from 'react'
import Link from 'gatsby-link'
import BodyClassName from 'react-body-classname';
import Img from "gatsby-image"
import '../layouts/artwork.styl'
import PageTransition from 'gatsby-v2-plugin-page-transitions'

export default class ArtIndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: artwork } = data.allMarkdownRemark
    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="artwork">

      <section className="artwork artworkList">

          {artwork
            .map(({ node: art }) => (
              <Link to={art.fields.slug} key={art.id}>
                <div className="art">
                  <Img fluid={art.frontmatter.thumb.childImageSharp.fluid} />
                  <h3 className="no-margin">
                      {art.frontmatter.title}
                  </h3>
                </div>
              </Link>
            ))}
      </section>
      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}

export const ArtworkQuery = graphql`
  query ArtIndexQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___collection, frontmatter___order] },
      filter: { frontmatter: { templateKey: { eq: "artwork" } }}
    ){
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            collection
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
